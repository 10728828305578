import React from 'react';
import ReactDOM from 'react-dom';
import DonutChart from './components/DonutChart';
//-- Styles
import "./styles/DonutChart.css";


ReactDOM.render(
  <React.StrictMode>
    <DonutChart />
  </React.StrictMode>,
  document.getElementById('root')
);